// https://greensock.com/st-demos/

// https://codepen.io/GreenSock/pen/OJyPmgX

gsap.registerPlugin(ScrollTrigger);



const images = gsap.utils.toArray('img');
const loader = document.querySelector('.loader--text');



const updateProgress = (instance) =>
  loader.textContent = `${Math.round(instance.progressedCount * 100 / images.length)}%`;



const showDemo = () => {
  document.body.style.overflow = 'auto';
  document.scrollingElement.scrollTo(0, 0);
  gsap.to(document.querySelector('.loader'), { autoAlpha: 0 });

};

imagesLoaded(images).on('progress', updateProgress).on('always', showDemo);



// Parallax header
// https://codepen.io/GreenSock/pen/OJyPmgX

const small = window.matchMedia("(max-width: 767px)");

var $parallax = document.getElementsByClassName('parallax');
if ($parallax.length > 0) {

  if(!small.matches) {

   
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".reference",
        start: "top top",
        end: "bottom top",
        scrub: true,
        //markers: true
      }
    });

    gsap.utils.toArray(".parallax").forEach(layer => {
      const depth = layer.dataset.depth;
      const movement = -(layer.offsetHeight * depth)
      tl.to(layer, { y: movement, ease: "none" }, 0)
    });
  }
  else {
  
    
  }
}



// Add class active to no-active
ScrollTrigger.create({
  trigger: ".no-active",
  //markers: true,
  start: "top bottom",
  end: "bottom top",
  toggleClass: { targets: ".no-active", className: "active" }
});




// Simple parallax sections - ScrollTrigger
gsap.utils.toArray(".cta_image").forEach((section, i) => {
  section.bg = section.querySelector(".parallax-image "); 

  // Give the backgrounds some random images
  //section.bg.style.backgroundImage = `url(https://picsum.photos/${innerWidth}/${innerHeight}?random=${i})`;

  // Do the parallax effect on each section
  if (i) {

    if(!small.matches) {

      section.bg.style.backgroundPosition = "50% 0px"; 

      gsap.to(section.bg, {
        backgroundPosition: `50% ${innerHeight / 2}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scrub: true,
          start: "top top", 
          end: "bottom top",
          //markers: true
        }
      });
    }
  } 
  
  // the first image should be positioned against the top. Use px on the animating part to work with GSAP. 
  else {

    if(!small.matches) {

      section.bg.style.backgroundPosition = "50% 0px"; 

      gsap.to(section.bg, {
        backgroundPosition: `50% ${innerHeight / 2}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "top top", 
          end: "bottom top",
          scrub: true
        }
      });
    }
  }

  
});











// .gs_reveal Reveal from left or right
function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
      y = direction * 100;
  if(elem.classList.contains("gs_reveal_fromLeft")) {
    x = -100;
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = 100;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
    duration: 1.25, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: "expo", 
    overwrite: "auto"
  });
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0});
}

document.addEventListener("DOMContentLoaded", function() {
  gsap.registerPlugin(ScrollTrigger);
  
  gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
    hide(elem); // assure that the element is hidden when scrolled into view
    
    ScrollTrigger.create({
      trigger: elem,
      onEnter: function() { animateFrom(elem) }, 
      onEnterBack: function() { animateFrom(elem, -1) },
      onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
    });
  });
});











// https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.batch()

ScrollTrigger.batch(".aparecer", {
  // interval: 0.1, // time window (in seconds) for batching to occur. 
  // batchMax: 3,   // maximum batch size (targets)
  onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.1 }),
  // also onLeave, onEnterBack, and onLeaveBack
  // also most normal ScrollTrigger values like start, end, etc.
});



/*
ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause"
});

//const w = section.querySelector('.wrapper');
//const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];

var $a2 = document.getElementsByClassName('a2');


if($a2.length > 0){
    
    gsap.from(".a2", {
      scrollTrigger: {
        trigger: ".a1",
        scrub: true,
        //pin: true, // pin the trigger element while active
        start: "top bottom",
        end: "center center"
      },
      y: "-90%", 
      transformOrigin: "top left", 
      //duration: 3
    });

}
*/







